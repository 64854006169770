'use client'
import React from 'react';
import styles from './loader.module.scss';

interface LoaderProps {
    loading: boolean;
    useFullWindow?: boolean;
    useNoBackground?: boolean;
}

const Loader: React.FC<LoaderProps> = ({ loading, useFullWindow = false, useNoBackground = false }) => {
    if (!loading) return null;

    return (
        <div className={useFullWindow ? styles.backdropFullWindow : (useNoBackground ? styles.noBackdrop : styles.backdrop)}>
            <div className={styles.loader}></div>
        </div>
    );
};

export default Loader;