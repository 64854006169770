"use client";
import Link from "next/link";
import React, { useState } from "react";
import { iMenu } from "../../models/iMenu";
import styles from "./navbar.module.scss";
import LinkElement from "../General/LinkElement";
import { eChevronColor } from "@/app/models/enum/chevronColor";
import { eChevronPosition } from "@/app/models/enum/chevronPosition";
import { eLinkSize } from "@/app/models/enum/linkSize";
import { eTextColor } from "@/app/models/enum/textColor";
import { eButtonColorTheme } from "@/app/models/enum/eButtonColorTheme";

interface Props {
  menu: iMenu;
  isLoggedIn: boolean;
}



const Navbar = ({ menu, isLoggedIn }: Props) => {
  const [nav, setNav] = useState(false);

  return (
    <div>
      <ul className="header-ul text-gs-white text-sm font-bold hidden lg:flex">
        {menu.links?.map((menuItem, index) => (
          <li key={`${menuItem.url}_${index}`}>
            <a href={menuItem.url} className="no-underline menu-link">{menuItem.linkText}</a>
          </li>
        ))}
        {!isLoggedIn && menu.myPagesLoginLink && (
          <li key="mypagesLogin">
            <LinkElement
              title={menu.myPagesLoginLink.linkText}
              buttonColorTheme={eButtonColorTheme.White}
              chevronPosition={eChevronPosition.None}
              isButton={true}
              href={menu.myPagesLoginLink.url}
              className="inline-important"
            >
              {menu.myPagesLoginLink.linkText}
            </LinkElement>
          </li>
        )}
        {isLoggedIn && menu.myPagesLoggedInLink && (
          <li key="mypagesLoggedIn">
            <LinkElement
              title={menu.myPagesLoginLink.linkText}
              buttonColorTheme={eButtonColorTheme.White}
              chevronPosition={eChevronPosition.None}
              isButton={true}
              href={menu.myPagesLoggedInLink.url}
              className="inline-important"
            >
              {menu.myPagesLoggedInLink.linkText}
            </LinkElement>
          </li>
        )}
      </ul>

      <div className={`lg:hidden flex items-center min-w-[151px]`}>
        {!isLoggedIn && menu.myPagesLoginLink && (
          <a href={menu.myPagesLoginLink.url} className={`btn white inline-important mr-4 ${styles.myPagesButton}`}>{menu.myPagesLoginLink.linkText}</a>
        )}
        {isLoggedIn && menu.myPagesLoggedInLink && (
          <a href={menu.myPagesLoggedInLink.url} className={`btn white inline-important mr-4 ${styles.myPagesButton}`}>{menu.myPagesLoggedInLink.linkText}</a>
        )}
        <div
          onClick={() => setNav(!nav)}
          className={`${styles.hamburger} ${nav ? styles.open : ''}`}
        >
          <div className={`${styles.bar} ${styles.topBar}`}></div>
          <div className={`${styles.bar} ${styles.middleBar}`}></div>
          <div className={`${styles.bar} ${styles.bottomBar}`}></div>
        </div>
      </div>
      <div className={`${styles.backgroundShield} lg:hidden`}></div>

      <div className={`${styles.mobileMenu} ${nav ? styles.open : ''}`}>
        <ul className="flex flex-col justify-center items-center w-full h-full p-10 gap-6 bg-gs-green-400 text-gs-white">
          {menu.links.map((menuItem, index) => (
            <li
              key={`${menuItem.url}_${index}`}
              className="text-sm no-underline w-full"
            >
              <LinkElement
                title={menuItem.linkText}
                onClick={() => setNav(!nav)}
                href={menuItem.url}
                chevronColor={eChevronColor.White}
                chevronPosition={eChevronPosition.Left}
                className={"text-white w-full  no-underline"}
                styleType={eTextColor.White}
                isStandalone={true}
                linkSize={eLinkSize.Large}
                onClickAndLink={true}>
                {menuItem.linkText}
              </LinkElement>

            </li>
          ))}
        </ul>
      </div>

    </div>
  );
};

export default Navbar;
