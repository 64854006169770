import { iAreaSimpelModel } from "../models/apiModels/areaSimpel";
import { iFacilitySimpelModel } from "../models/apiModels/facilitySimpel";
import { eAreaType } from "../models/enum/areaType";

export function findAreaById(areas: iAreaSimpelModel[], currentAreaId: number): iAreaSimpelModel | null {
    for (const area of areas) {
        if (area.id === currentAreaId) {
            return area;
        }
        if (area.subAreas && area.subAreas.length > 0) {
            const foundArea = findAreaById(area.subAreas, currentAreaId);
            if (foundArea) {
                return foundArea;
            }
        }
    }
    return null;
}

export function findFacilitiesByAreaId(areas: iAreaSimpelModel[], currentAreaId: number): iFacilitySimpelModel[] {
    let facilities: iFacilitySimpelModel[] = [];

    function collectFacilities(area: iAreaSimpelModel) {
        facilities = facilities.concat(area.facilities);
        if (area.subAreas && area.subAreas.length > 0) {
            for (const subArea of area.subAreas) {
                collectFacilities(subArea);
            }
        }
    }

    for (const area of areas) {
        if (area.id === currentAreaId) {
            collectFacilities(area);
            break;
        }
        if (area.subAreas && area.subAreas.length > 0) {
            const foundArea = findAreaById(area.subAreas, currentAreaId);
            if (foundArea) {
                collectFacilities(foundArea);
                break;
            }
        }
    }

    return facilities;
}

export function getAreasByType(areas: iAreaSimpelModel[], areaType: eAreaType): iAreaSimpelModel[] {
    let foundAreas: iAreaSimpelModel[] = [];

    function collectAreas(area: iAreaSimpelModel) {
        if (area.areaType === areaType) {
            foundAreas.push(area);
        }
        if (area.subAreas && area.subAreas.length > 0) {
            for (const subArea of area.subAreas) {
                collectAreas(subArea);
            }
        }
    }

    for (const area of areas) {
        collectAreas(area);
    }

    return foundAreas;
}

export function findAreaByFacilityId(areas: iAreaSimpelModel[], facilityId: string): iAreaSimpelModel | null {
    for (const area of areas) {
        // Check if the facility is in the current area
        if (area.facilities.some(facility => facility.id === facilityId)) {
            return area;
        }
        // Recursively check sub-areas
        if (area.subAreas && area.subAreas.length > 0) {
            const foundArea = findAreaByFacilityId(area.subAreas, facilityId);
            if (foundArea) {
                return foundArea;
            }
        }
    }
    return null;
}

export function findFacilityById(areas: iAreaSimpelModel[], facilityId: string): iFacilitySimpelModel | undefined {
    function searchFacilities(area: iAreaSimpelModel): iFacilitySimpelModel | undefined {
        // Check if the facility is in the current area
        const facility = area.facilities.find(facility => facility.id === facilityId);
        if (facility) {
            return facility;
        }
        // Recursively check sub-areas
        if (area.subAreas && area.subAreas.length > 0) {
            for (const subArea of area.subAreas) {
                const foundFacility = searchFacilities(subArea);
                if (foundFacility) {
                    return foundFacility;
                }
            }
        }
        return undefined;
    }

    for (const area of areas) {
        const facility = searchFacilities(area);
        if (facility) {
            return facility;
        }
    }

    return undefined;
}

export function hasFacilities(area: iAreaSimpelModel): boolean {
    if (area.facilities && area.facilities.length > 0) {
        return true;
    }
    if (area.subAreas && area.subAreas.length > 0) {
        return area.subAreas.some(subArea => hasFacilities(subArea));
    }
    return false;
};