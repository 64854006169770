"use client"

import { eLogLevel } from "../models/enum/logLevel";
const DEFAULT_LOG_LEVEL = eLogLevel.Error;
const minimumLogLevel: eLogLevel = getLogLevelFromEnv();

async function sendLog(level: eLogLevel, messages: any[]): Promise<void> {
    if (!shouldLog(level)) {
        return;
    }

    let levelString = "logError";

    switch (level) {
        case eLogLevel.Info:
            levelString = "logInfo";
            break;
        case eLogLevel.Debug:
            levelString = "logDebug";
            break;
        case eLogLevel.Warn:
            levelString = "logWarn";
            break;
        case eLogLevel.Error:
            levelString = "logError";
            break;
        default:
            levelString = "logError";
            break;
    }

    messages.unshift("CLIENT");

    const flattenedMessages = messages.flat();
    messages = flattenedMessages
        .map((msg) => {
            if (msg instanceof Error) {
                return JSON.stringify(msg, Object.getOwnPropertyNames(msg));
            }
            return typeof msg === "object" ? JSON.stringify(msg) : msg;
        });


    try {
        const baseUrl = process.env.NEXT_PUBLIC_FRONTEND_URL;
        const url = `${baseUrl}/api/logging/${levelString}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ messages }),
        });

        if (!response.ok) {
            console.error(`Failed to log ${level} message: ${response.statusText}. To url: ${url}`);
        }
    } catch (error) {
        console.error(`Error logging ${level} message:`, error);
    }
}

export function clientLogInfo(...messages: any[]): void {
    sendLog(eLogLevel.Info, messages);
}

export function clientLogDebug(...messages: any[]): void {
    sendLog(eLogLevel.Debug, messages);
}

export function clientLogWarn(...messages: any[]): void {
    sendLog(eLogLevel.Warn, messages);
}

export function clientLogError(...messages: any[]): void {
    sendLog(eLogLevel.Error, messages);
}


const logLevelPriority: { [key in eLogLevel]: number } = {
    [eLogLevel.Info]: 1,
    [eLogLevel.Debug]: 2,
    [eLogLevel.Warn]: 3,
    [eLogLevel.Error]: 4,
};

function getLogLevelFromEnv(): eLogLevel {
    const logLevel = process.env.NEXT_PUBLIC_LOG_LEVEL?.toUpperCase();
    switch (logLevel) {
        case "INFO":
            return eLogLevel.Info;
        case "DEBUG":
            return eLogLevel.Debug;
        case "WARN":
            return eLogLevel.Warn;
        case "ERROR":
            return eLogLevel.Error;
        default:
            return DEFAULT_LOG_LEVEL;
    }
}

function shouldLog(level: eLogLevel): boolean {
    return logLevelPriority[level] >= logLevelPriority[minimumLogLevel];
}