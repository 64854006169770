export enum eAreaType {
    Country = 1,
    Region = 2,
    City = 3,
    District = 4 
}

export const eAreaTypeStrings: { [key in eAreaType]: string } = {
    [eAreaType.Country]: "Country",
    [eAreaType.Region]: "Region",
    [eAreaType.City]: "City",
    [eAreaType.District]: "District"
};