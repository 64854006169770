import { eChevronColor } from '@/app/models/enum/chevronColor';
import { eChevronPosition } from '@/app/models/enum/chevronPosition';
import { eButtonColorTheme } from '@/app/models/enum/eButtonColorTheme';
import { eLinkSize } from '@/app/models/enum/linkSize';
import { eLinkType } from '@/app/models/enum/linkType';
import { eTarget } from '@/app/models/enum/target';
import { eTextColor } from '@/app/models/enum/textColor';
import React from 'react';

interface iLinkProps {
    href?: string;
    children: React.ReactNode;
    linkSize?: eLinkSize;
    styleType?: eTextColor;
    chevronColor?: eChevronColor;
    chevronPosition?: eChevronPosition;
    buttonColorTheme?: eButtonColorTheme;
    linkType?: eLinkType;
    className?: string;
    title: string;
    target?: eTarget;
    isStandalone?: boolean;
    isButton?: boolean;
    onClick?: () => void;
    onClickAndLink?: boolean;
    disabled?: boolean;
}

const LinkElement: React.FC<iLinkProps> = ({
    styleType = eTextColor.Black,
    chevronColor = eChevronColor.Green,
    chevronPosition = eChevronPosition.None,
    buttonColorTheme = eButtonColorTheme.Green,
    linkType = eLinkType.Ordinary,
    linkSize = eLinkSize.Standard,
    className = '',
    target = eTarget.Self,
    href,
    children,
    title,
    isStandalone = false,
    onClick,
    isButton = false,
    onClickAndLink = false,
    disabled = false
}) => {
    const getLinkHref = () => {
        switch (linkType) {
            case eLinkType.Tel:
                return `tel:${href}`;
            case eLinkType.Mailto:
                return `mailto:${href}`;
            default:
                return href;
        }
    };

    const commonProps = {
        className: `${isButton ? "btn" : "link"} ${styleType} ${buttonColorTheme} ${isStandalone || chevronPosition !== eChevronPosition.None ? "standalone" : ""} ${chevronPosition !== eChevronPosition.None ? "chevron" : ""} ${chevronColor} ${chevronPosition} ${className} ${linkSize}`,
        title,
        disabled
    };

    
    if (onClickAndLink && onClick && href) {
        return (<a {...commonProps} href={getLinkHref()} target={target} onClick={onClick} rel="noopener" >
            {children}
        </a >);
    } else if (onClick) {
        return (<button {...commonProps} onClick={onClick}>
            {children}
        </button>);
    } else {
        return (<a {...commonProps} href={getLinkHref()} target={target} rel="noopener" >
            {children}
        </a >);
    }
};

export default LinkElement;