// import { BlobServiceClient } from "@azure/storage-blob";
import { eLogLevel } from "../models/enum/logLevel";

const AZURE_BLOB_STORAGE_CONNECTION_STRING = process.env
  .AZURE_BLOB_STORAGE_CONNECTION_STRING as string;
const CONTAINER_NAME = "log";
const DEFAULT_LOG_LEVEL = eLogLevel.Error;

const minimumLogLevel: eLogLevel = getLogLevelFromEnv();

const logLevelPriority: { [key in eLogLevel]: number } = {
  [eLogLevel.Info]: 1,
  [eLogLevel.Debug]: 2,
  [eLogLevel.Warn]: 3,
  [eLogLevel.Error]: 4,
};

function getLogLevelFromEnv(): eLogLevel {
  const logLevel = process.env.LOG_LEVEL?.toUpperCase();
  switch (logLevel) {
    case "INFO":
      return eLogLevel.Info;
    case "DEBUG":
      return eLogLevel.Debug;
    case "WARN":
      return eLogLevel.Warn;
    case "ERROR":
      return eLogLevel.Error;
    default:
      return DEFAULT_LOG_LEVEL;
  }
}

function shouldLog(level: eLogLevel): boolean {
  return logLevelPriority[level] >= logLevelPriority[minimumLogLevel];
}

export async function logToBlob(logMessage: string): Promise<void> {
  if (typeof window !== 'undefined') {
    console.error("logToBlob should not be called from client-side code.");
    return;
  }
  if (!AZURE_BLOB_STORAGE_CONNECTION_STRING) {
    console.error(
      "Azure Storage connection string is not defined in environment variables."
    );
    return;
  }
  const { BlobServiceClient, AppendBlobClient } = await import("@azure/storage-blob");
  const blobServiceClient = BlobServiceClient.fromConnectionString(
    AZURE_BLOB_STORAGE_CONNECTION_STRING
  );
  const containerClient = blobServiceClient.getContainerClient(CONTAINER_NAME);

  await containerClient.createIfNotExists();

  const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
  const blobName = `nextjs/nextjs-${currentDate}.log`;
  const appendBlobClient = containerClient.getAppendBlobClient(blobName);

  try {
    await appendBlobClient.createIfNotExists();
    await appendBlobClient.setHTTPHeaders({
      blobContentType: "text/plain"
    });
  } catch (error) {
    console.error("Error creating append blob:", error);
    return;
  }

  try {
    await appendBlobClient.appendBlock(Buffer.from(logMessage + '\n', "utf-8"), logMessage.length);
  } catch (error) {
    console.error("Error appending to blob:", error);
  }
}

export function log(message: string, level: eLogLevel = DEFAULT_LOG_LEVEL): void {
  if (!shouldLog(level)) {
    return;
  }

  const timestamp = new Date().toISOString().replace('T', ' ').split('.')[0];
  const logMessage = `[${timestamp} ${level}] ${message}`;
  if (typeof window !== 'undefined') {
    console.log('Call is coming from client side');
  }

  if (typeof window === 'undefined' && (process.env.NODE_ENV?.toLowerCase() === "production" || process.env.NODE_ENV?.toLowerCase() === "staging")) {
    logToBlob(formatErrorMessage(logMessage)).catch(console.error);
  } else {
    switch (level) {
      case eLogLevel.Info:
        console.info(logMessage);
        break;
      case eLogLevel.Debug:
        console.debug(logMessage);
        break;
      case eLogLevel.Warn:
        console.warn(logMessage);
        break;
      case eLogLevel.Error:
        console.error(formatErrorMessage(logMessage));
        break;
    }
  }
}

function formatErrorMessage(message: string): string {
  return message.replace(/\\n/g, '\n');
}

export function logInfo(...messages: any[]): void {
  const flattenedMessages = messages.flat();
  const logMessage = flattenedMessages
    .map((msg) => {
      if (msg instanceof Error) {
        return JSON.stringify(msg, Object.getOwnPropertyNames(msg));
      }
      return typeof msg === "object" ? JSON.stringify(msg) : msg;
    })
    .join(" | ");

  log(logMessage, eLogLevel.Info);
}

export function logDebug(...messages: any[]): void {
  const flattenedMessages = messages.flat();
  const logMessage = flattenedMessages
    .map((msg) => {
      if (msg instanceof Error) {
        return JSON.stringify(msg, Object.getOwnPropertyNames(msg));
      }
      return typeof msg === "object" ? JSON.stringify(msg) : msg;
    })
    .join(" | ");

  log(logMessage, eLogLevel.Debug);
}

export function logWarn(...messages: any[]): void {
  const flattenedMessages = messages.flat();
  const logMessage = flattenedMessages
    .map((msg) => {
      if (msg instanceof Error) {
        return JSON.stringify(msg, Object.getOwnPropertyNames(msg));
      }
      return typeof msg === "object" ? JSON.stringify(msg) : msg;
    })
    .join(" | ");

  log(logMessage, eLogLevel.Warn);
}

export function logError(...messages: any[]): void {
  const flattenedMessages = messages.flat();
  const logMessage = flattenedMessages
    .map((msg) => {
      if (msg instanceof Error) {
        return JSON.stringify(msg, Object.getOwnPropertyNames(msg));
      }
      return typeof msg === "object" ? JSON.stringify(msg) : msg;
    })
    .join(" | ");

  log(logMessage, eLogLevel.Error);
}


async function streamToString(readableStream: NodeJS.ReadableStream | null): Promise<string> {
  if (!readableStream) {
    return '';
  }
  return new Promise((resolve, reject) => {
    const chunks: any[] = [];
    readableStream.on("data", (data) => {
      chunks.push(data.toString());
    });
    readableStream.on("end", () => {
      resolve(chunks.join(""));
    });
    readableStream.on("error", reject);
  });
}

function isErrorWithStatusCode(error: any): error is { statusCode: number } {
  return error && typeof error.statusCode === 'number';
}