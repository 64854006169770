"use client"
import styles from './footerfacilityitem.module.scss';
import { iFacilitySimpelModel } from '@/app/models/apiModels/facilitySimpel';

interface Props {
    facility: iFacilitySimpelModel;
    translations: any;
}


export default function FooterFacilityItem({ facility, translations }: Props) {
    return (
        <div className="flex justify-between items-center">
            <a 
            href={facility.path} 
            title={`${translations.general.goTo} ${translations.general.theFacility} ${facility.name}`} 
            aria-label={`${translations.general.goTo} ${translations.general.theFacility} ${facility.name}`} 
            className={`flex-grow text-left mr-4 ${styles.link}`}>
                {facility.name}
            </a>
        </div>
    );
}